import React, { useState } from 'react';
import './styles.scss';
import { Box, Card, CardActionArea, CardContent, Typography, Paper, Container, Grid } from '@mui/material';
import { scenarios } from '../../../data';
import Typewriter from 'typewriter-effect';

function TypewriterText({ text }) {
    return (
        <Typography variant="body1" gutterBottom>
            <Typewriter
                options={{
                    strings: text,
                    autoStart: true,
                    loop: true,
                    delay: 80,
                }}
            />
        </Typography>
    );
}

const ScenariosComponentUpdated = () => {
    const [selectedScenario, setSelectedScenario] = useState(scenarios[0]);

    return (
        <Box className='ScenariosComponentUpdated'>
            <Paper elevation={3} className="selected-scenario-details">
                <Box
                    className="selected-scenario-image"
                    component="img"
                    alt={selectedScenario.name}
                    src={selectedScenario.icon}
                />
            </Paper>
            <Grid container spacing={2} justifyContent="center" alignItems="stretch" sx={{ padding: "0px 32px" }}>
                {scenarios.map((scenario, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                        <Card
                            elevation={3}
                            className={`scenario-card ${selectedScenario.name === scenario.name ? 'active' : ''}`}
                            onClick={() => setSelectedScenario(scenario)}
                        >
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {scenario.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {scenario.sub}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>

        </Box>
    );
};

export default ScenariosComponentUpdated;
