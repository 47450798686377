import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import platform from '../../../assets/images/icons/platform.png';
import capabilities from '../../../assets/images/icons/capabilities.png';
import behavior from '../../../assets/images/icons/behavior.png';
import eye from '../../../assets/images/icons/eye.png';
import plus from '../../../assets/images/icons/plus.png';
import './styles.scss';

const HomePageThirdComponent = () => {
    return (
        <Box className="HomePageThirdComponentWrapper">
            <Container>
                <Grid container>
                    <Grid item xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <Box className="HomePageThirdComponentImagesContainer">
                            <Box className="HomePageThirdComponentImages">
                                <img src={platform} width={90} height={90} />
                                <Typography variant='span22' className="HomePageThirdComponentImagesText">
                                    Single Platform
                                </Typography>
                            </Box>
                            <Box className="HomePageThirdComponentImages">
                                <img src={capabilities} width={90} height={90} />
                                <Typography variant='span22' className="HomePageThirdComponentImagesText">
                                    Varied Capabilities
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <Box>
                            <Typography variant='h3' fontWeight='bold' marginBottom={1}>
                                Dynamic Governance
                            </Typography>
                            <Typography variant='span16'>
                                Our platform offers an intuitive interface for fraud prevention management,
                                freeing up your schedule to focus on core business priorities. It's an all-encompassing,
                                modern solution crafted for immediate integration in the digital landscape.
                                Our flexible approach allows for the seamless activation of needed features,
                                ensuring peak efficiency and streamlined oversight.
                            </Typography>

                        </Box>
                    </Grid>

                    <Grid item xxl={7} xl={7} lg={7} md={7} sm={12} xs={12} marginTop={7}>
                        <Box>
                            <Typography variant='h3' fontWeight='bold'>
                                Rewarding Outcomes
                            </Typography>
                            <Typography variant='span16'>
                                Our services reflect our deep expertise in cybersecurity and fraud prevention.
                                By integrating our exclusive AI algorithms with comprehensive behavioral analytics,
                                we ensure a frictionless and fortified experience for users. Additionally, we offer
                                wide-ranging defense across your platforms, enabling you to implement powerful anti-fraud strategies.
                            </Typography>

                        </Box>
                    </Grid>
                    <Grid item xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} marginTop={7}>
                        <Box className="HomePageThirdComponentImagesContainer">
                            <Box className="HomePageThirdComponentImages">
                                <img src={behavior} width={80} height={80} />
                                <Typography variant='span22' className="HomePageThirdComponentImagesText">
                                    Interaction-based
                                </Typography>
                            </Box>
                            <Box className="HomePageThirdComponentImages">
                                <img src={eye} width={90} height={90} />
                                <Typography variant='span22' className="HomePageThirdComponentImagesText">
                                    Seamless
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default HomePageThirdComponent