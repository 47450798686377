import React from 'react'
import './styles.scss'
import { Box, Container, Typography } from '@mui/material'
import { aboutUs } from '../../../data'
import { arabToRoman } from 'roman-numbers'

const AboutUsSecondComponent = () => {
    return (
        <Container sx={{ padding: '32px 0px' }}>
            <Box className="AboutUsSecondComponentTitlesContainer">
                <Typography variant='h2' className='AboutUsSecondComponentTitle'>
                    Why Choose Us?
                </Typography>
                <Typography variant='span18' className='AboutUsSecondComponentSubTitle'>
                    In partnership with your digital journey, we dedicate ourselves to fostering a safe and supportive space for expansion.
                    Putting your faith in us means you're ensuring your digital community is in trustworthy care.
                </Typography>

            </Box>
            <Box className='AboutUsSecondComponentCardsContainer'>
                {
                    aboutUs?.map((about, index) => {
                        return (
                            <Box class="card">
                                <Box class="content">
                                    <Box sx={{ display: "flex", alignItems: "center", gap: '8px' }}>
                                        <Typography variant='h3'>
                                            {index + 1}.
                                        </Typography>
                                        <Typography variant="span18" fontWeight='bold'>
                                            {about?.title}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ marginTop: "8px" }}>
                                        <Typography variant='spanM' className='AboutUsSecondComponentCardSubTitle'>
                                            {about?.subTitle}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })
                }


            </Box>
        </Container>
    )
}

export default AboutUsSecondComponent