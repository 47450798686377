import React from 'react'
import './styles.scss'
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import bye from '../../../assets/images/icons/bye.png'
import security from '../../../assets/images/icons/security.png'

const HomePageFirstComponent = () => {

    const mobile = useMediaQuery('(max-width:800px)')


    return (
        <>
            <Box className='HomePageFirstComponentWrapper'>
                <Container>
                    <Grid container>
                        <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Box className="HomePageFirstComponentTitlesContainer">
                                <Typography variant='h2' className='HomePageFirstComponentTitle'>
                                    Trust in your digital interactions.
                                </Typography>
                                <br />
                                <Typography variant='span18' className='HomePageFirstComponentSubTitle'>
                                    Highlighting the importance of user authenticity on digital platforms, we proactively combat fraud and threats by deploying an advanced real-time behavioral analysis system. Our primary goal is the creation and upkeep of a dependable online space that empowers user confidence and promotes secure engagement. This dedication drives us to foster an online realm where users can participate with assurance, without the worry of risks or deceptive activities.
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Box className='HomePageFirstComponentDataContainer' >
                                <Box
                                    className="HomePageFirstComponentDataBox"
                                >
                                    <Box className="HomePageFirstComponentDataImage">
                                        <img src={bye} width={70} height={70} />
                                    </Box>
                                    <Typography variant='h6' className="HomePageFirstComponentDataText">Eradicate Cyber Fraud Threats</Typography>
                                </Box>
                                <Box
                                    className="HomePageFirstComponentDataBox"
                                    sx={{ marginTop: "16px" }}
                                >
                                    <Box className="HomePageFirstComponentDataImage2">
                                        <img src={security} width={70} height={70} />
                                    </Box>
                                    <Typography variant='h6' className="HomePageFirstComponentDataText">Fortify Brand Integrity</Typography>
                                </Box>
                            </Box>

                        </Grid>
                    </Grid>


                </Container>
            </Box>
        </>
    )
}

export default HomePageFirstComponent