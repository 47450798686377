import React from 'react'
import AboutUsFourthComponent from '../../component/aboutus/aboutUsFourthComponent'
import AboutUsSecondComponent from '../../component/aboutus/aboutUsSecondComponent'
import AboutUsThirdComponent from '../../component/aboutus/aboutUsThirdComponent'
import PageIntroduciton from '../../component/common/introduction'

const AboutUs = () => {
    return (
        <>
            <PageIntroduciton title="About Us" description1="With our deep expertise in cybersecurity, we possess" description2='a comprehensive understanding of your challenges.' />
            <AboutUsSecondComponent />
            <AboutUsFourthComponent />
            <AboutUsThirdComponent />
        </>
    )
}

export default AboutUs