import React, { useState } from 'react';
import { Card, CardContent, TextField, Button, Box, Typography, FormControl } from '@mui/material';
import { Form, Link, useNavigate } from 'react-router-dom';
import './styles.scss'
import Cookies from 'universal-cookie';
import { useFormik } from 'formik';
import { login } from '../../api';
import HomeIcon from '@mui/icons-material/Home';

const Signin = () => {
    const [message, setMessage] = useState()
    const cookie = new Cookies()
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: () => {
            const username = formik.values.username
            const password = formik.values.password;
            const func = login(username, password)
            if (username === "" && password === "") {
                navigate('/signin');
            } else {
                func.then(async (response) => {
                    if (response.success) {
                        cookie.set('token', response.token, { path: '/' })
                        cookie.set('firstname', response.data.firstName, { path: '/' })
                        cookie.set('lastname', response.data.lastName, { path: '/' })
                        cookie.set('username', response.data.username, { path: '/' })
                        cookie.set('id', response.data._id, { path: '/' })
                        navigate('/');
                    } else {
                        setMessage('User not found')
                    }
                })
            }

        }
    })
    return (
        <Box className='signinWrapper'>
            <Box class="signinbackground">
                <Box class="signinshape"></Box>
                <Box class="signinshape"></Box>
            </Box>
            <form onSubmit={formik.handleSubmit}>
                <h3>Login</h3>
                <Typography sx={{ textAlign: "center" }}>
                    {message}
                </Typography>
                <label for="username">Username</label>
                <input
                    type="text"
                    placeholder="Email or Phone"
                    id="username"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.username || ''}
                />

                <label for="password">Password</label>
                <input
                    type="password"
                    placeholder="Password"
                    id="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password || ''}
                />

                <button type="submit">Log In</button>
                <Link to='/'>
                    <Box class="signinback"><HomeIcon /></Box>
                </Link>
            </form>
        </Box>
    )
}

export default Signin