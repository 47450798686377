import React from 'react'
import './styles.scss'
import { Box, Button, Container, Typography } from '@mui/material'
import { scenarios } from '../../../data'
import TurnRightOutlinedIcon from '@mui/icons-material/TurnRightOutlined';
import { Link } from 'react-router-dom';

const HomePageFourthComponent = () => {
    return (
        <Box className="HomePageFourthComponentWrapper">
            <Box className="HomePageFourthComponentTitlesContainer">
                <Typography variant='h2' className='HomePageFourthComponentTitle'>
                    Understanding Your Daily Digital Hurdles
                </Typography>
                <Typography variant='span22' className='HomePageFourthComponentSubTitle'>
                    Discover how our innovations protect your online business.
                </Typography>

            </Box>

            {/* <Container> */}
            <Box className="HomePageFourthComponentCardsContainer">
                {
                    scenarios?.slice(0, 5)?.map(scenario => {
                        return (
                            <Box className="HomePageFourthComponentCard">
                                <Box className='HomePageFourthComponentCardIconContainer'>
                                    <img src={scenario?.icon} width={scenario?.width} height={scenario?.height} alt={scenario?.name} />
                                    <Typography variant='span22' className='HomePageFourthComponentCardTitle'>
                                        {scenario?.name}
                                    </Typography>
                                </Box>
                                <Box className="HomePageFourthComponentCardTitlesContainer">
                                    <Typography variant='span16' className='HomePageFourthComponentCardSub'>
                                        {scenario?.sub}
                                    </Typography>
                                    <Box className="HomePageFourthComponentButtonContainer">
                                        <Link to='/scenarios'>
                                            <Button className='HomePageFourthComponentButton'>
                                                <TurnRightOutlinedIcon />
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>

                            </Box>
                        )
                    })
                }
            </Box>
            {/* </Container> */}
        </Box>
    )
}

export default HomePageFourthComponent