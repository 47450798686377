import React from 'react'
import PageIntroduciton from '../../component/common/introduction'
import SystemFirstComponent from '../../component/system/SystemFirstComponent'
import SystemSecondComponent from '../../component/system/SystemSecondComponent'
import SystemThirdComponent from '../../component/system/SystemThirdComponent'

const System = () => {
    return (
        <>
            <PageIntroduciton title="SYSTEM" />
            <SystemSecondComponent />
            {/* <SystemThirdComponent /> */}
            <SystemFirstComponent />
        </>
    )
}

export default System