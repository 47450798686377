import { Box, Typography } from '@mui/material'
import React from 'react'
import pageBackground from '../../../assets/images/page-background.jpg'
import './styles.scss'

const PageIntroduciton = ({ title, description1, description2, fullPage }) => {
    return (
        <Box className="AboutUsFirstComponentContainer" sx={{ height: fullPage ? '100vh' : '60vh' }}>
            <img src={pageBackground} className='AboutUsFirstComponentImage' alt='background3' />
            <Box className='AboutUsFirstComponentOverlay' sx={{ top: fullPage ? '10%' : '25%' }}>
                <Typography variant='h1' className='AboutUsFirstComponentOverlayTitle'>
                    {title}
                </Typography>
                <Typography variant='span22' className='AboutUsFirstComponentOverlaySubTitle'>
                    {description1}<br />{description2}
                </Typography>

            </Box>
        </Box>
    )
}

export default PageIntroduciton