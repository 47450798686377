import React from 'react'
import './styles.scss'
import { Box, Typography, Button } from '@mui/material'
import moment from 'moment'
import investguard from '../../../assets/images/w-logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <Box className="footerWrapper">
            {/* <Box className="footerLinksContainer">
                <Typography variant='span18' className='footerLink'>
                    Home
                </Typography>
                <Typography variant='span18' className='footerLink'>
                    About Us
                </Typography>
                <Typography variant='span18' className='footerLink'>
                    Scenarios
                </Typography>
                <Typography variant='span18' className='footerLink'>
                    System
                </Typography>
            </Box> */}
            <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between", padding: '0px 24px', flexWrap: "wrap" }}>
                <Box textAlign='center' fontSize={28} margin='16px 0px' display='flex' alignItems='center' justifyContent='center'>
                    <img src={investguard} width={45} height={50} alt='footer-logo' />
                    <Typography variant='h6' color='white' ml={1} fontWeight='bold'>MsRecover</Typography>
                </Box>
                <Box className="footerLinksContainer">
                    <Typography variant='span18' className='footerLink'>
                        Home
                    </Typography>
                    <Typography variant='span18' className='footerLink'>
                        About Us
                    </Typography>
                    <Typography variant='span18' className='footerLink'>
                        Scenarios
                    </Typography>
                    <Typography variant='span18' className='footerLink'>
                        System
                    </Typography>
                </Box>
                <Box>
                    <Button className='StartYourJourneyButton'>
                        <Link to='/signin'>
                            Start Yout Journey Today
                        </Link>
                    </Button>
                </Box>
            </Box>
            <Box className='footerCopyRight'>
                <Typography variant='span16' >
                    ©{moment().format('YYYY')} - All Rights Reserved
                </Typography>
            </Box>
        </Box>
    )
}

export default Footer