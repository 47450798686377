import React from 'react'
import './styles.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import background from '../../../assets/images/background.jpg'
import background2 from '../../../assets/images/background2.jpg'
import background3 from '../../../assets/images/background3.jpg'
import { Pagination, Autoplay } from 'swiper/modules';
import { Box, Button, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';

const SlideShow = () => {

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
        },
    };

    return (
        <>
            <Swiper
                loop={true}
                pagination={pagination}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                modules={[Pagination, Autoplay]}
                className="SldieShowWrapper"
            >
                <SwiperSlide>
                    <Box className="SlideShowItemContainer">
                        <img src={background} className='SlideShowItemImage' alt='background' />
                        <Box className='SlideShowtemOverlay'>
                            <Typography variant='h1' className='SlideShowtemOverlayTitle'>
                                Attract authentic users, dispel deceptive dealings!
                            </Typography>
                            <Typography variant='span22' className='SlideShowtemOverlaySubTitle'>
                                Bid farewell to new account fraud!
                            </Typography>
                            <Link to='/signin'>
                                <Button className='SlideShowtemOverlayButton1'>
                                    Learn More <KeyboardArrowRightIcon color='#fff' />
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </SwiperSlide>
                <SwiperSlide>
                    <Box className="SlideShowItemContainer">
                        <img src={background2} className='SlideShowItemImage' alt='background2' />
                        <Box className='SlideShowtemOverlay'>
                            <Typography variant='h1' className='SlideShowtemOverlayTitle'>
                                Eliminate Account Takeover and coupon deception<br /> throughout the gaming collective.
                            </Typography>
                            <Typography variant='span22' className='SlideShowtemOverlaySubTitle'>
                                Discover our triumph in fully obstructing<br /> Account Takeover and Coupon Fraud within the gaming community.
                            </Typography>
                            <Link to='/about-us'>
                                <Button className='SlideShowtemOverlayButton2'>
                                    More About Us
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </SwiperSlide>
                <SwiperSlide>
                    <Box className="SlideShowItemContainer">
                        <img src={background3} className='SlideShowItemImage' alt='background3' />
                        <Box className='SlideShowtemOverlay'>
                            <Typography variant='h1' className='SlideShowtemOverlayTitle'>
                                Enhance your online platform with <br /> advanced customer security measures.
                            </Typography>
                            <Typography variant='span22' className='SlideShowtemOverlaySubTitle'>
                                We're committed to fortifying web services worldwide,<br /> providing robust protection against online fraud and cyber risks.
                            </Typography>

                            {/* <Link to='/system'>
                                <Button className='SlideShowtemOverlayButton3'>
                                    Discover our System <KeyboardArrowRightIcon color='#fff' />
                                </Button>
                            </Link> */}
                        </Box>
                    </Box>
                </SwiperSlide>

            </Swiper>
        </>
    )
}

export default SlideShow