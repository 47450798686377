import icon1 from './assets/images/icons/icon1.png'
import icon2 from './assets/images/icons/icon2.png'
import icon3 from './assets/images/icons/icon3.png'
import icon4 from './assets/images/icons/icon4.png'
import icon5 from './assets/images/icons/icon5.png'
import icon6 from './assets/images/icons/icon6.png'
import icon7 from './assets/images/icons/icon7.png'
import icon8 from './assets/images/icons/icon8.png'
import icon9 from './assets/images/icons/icon9.png'
import icon10 from './assets/images/icons/icon10.png'
import internet from './assets/images/icons/internet.png'
import fintech from './assets/images/icons/fintech.png'
import car from './assets/images/icons/car.png'
import bet from './assets/images/icons/bet.png'
import system1 from './assets/images/icons/system1.png'
import system2 from './assets/images/icons/system2.png'
import system3 from './assets/images/icons/system3.png'
import system4 from './assets/images/icons/system4.png'
import system5 from './assets/images/icons/system5.png'
import system6 from './assets/images/icons/system6.png'

export const scenarios = [
    {
        name: 'Unauthorized Account Access Prevention',
        sub: 'Ensure the security of user accounts while ensuring a seamless experience.',
        icon: icon1,
        width: '70',
        height: '70'
    },
    {
        name: 'Anti-Registration Fraud Measures',
        sub: 'Prevent fraudulent activities during the account opening process.',
        icon: icon2,
        width: '70',
        height: '70'

    },
    {
        name: 'Transaction Security',
        sub: 'Effectively tackle payment fraud using a holistic, omnichannel protection strategy.',
        icon: icon3,
        width: '70',
        height: '60'
    },
    {
        name: 'Credit Card Fraud Prevention',
        sub: 'Equip your banks with real-time fraud protection and analyze user behavior to mitigate the risk of fraudulent transactions.',
        icon: icon4,
        width: '70',
        height: '60'
    },
    {
        name: 'Detection of Money Laundering Accounts',
        sub: 'Address the money mule threat promptly, reducing manual efforts for fraud analysts.',
        icon: icon5,
        width: '65',
        height: '60'
    },
    {
        name: 'Protection Against Push Payment Scams',
        sub: 'Counter fraud resulting from Social Engineering Scams through user behavior analysis and a comprehensive approach.',
        icon: icon6,
        width: '70',
        height: '70'
    },
    {
        name: 'Safeguarding Against Policy Fraud',
        sub: 'Enhance security by protecting against Policy Abuse using behavioral biometrics.',
        icon: icon7,
        width: '70',
        height: '70'
    },
    {
        name: 'Automated Attacks Prevention',
        sub: 'Safeguard your business against Bot Attacks using XTNs robust Behavioral Analysis technology.',
        icon: icon8,
        width: '70',
        height: '70'
    },
    {
        name: 'Malicious Software Safeguard',
        sub: 'Secure your sensitive digital services, preventing them from becoming targets of malware campaigns.',
        icon: icon9,
        width: '70',
        height: '70'
    },
    {
        name: 'Electronic Commerce Security Measures',
        sub: 'Detect fraudulent activity by monitoring your customers digital profiles.',
        icon: icon10,
        width: '70',
        height: '70'
    },
]

export const aboutUs = [
    {
        title: "Reliable Collaborations",
        subTitle:
            "Join us on a mutual journey to build a safer digital environment for your business's expansion. Trusting us equates to a commitment to protect your online community.",
    },
    {
        title: "Driven by Results",
        subTitle:
            "Your victories are our victories. Our technologies enable your business to guard against fraud, securing success for you and your customers.",
    },
    {
        title: "Enhanced Operational Productivity",
        subTitle:
            "Optimize your operations using our advanced solutions. Minimize implementation expenses, achieve regulatory compliance, automate workflows, and reallocate resources towards bigger goals.",
    },
    {
        title: "Defending Your Brand's Integrity",
        subTitle:
            "Our mission is to fortify your brand against the aftereffects of cyber threats and fraudulent activities, preserving your good name.",
    },
    {
        title: "Flexible Modular Solutions",
        subTitle:
            "Discover the adaptability of our Cognitive Security Platform® - a unified solution offering diverse features for comprehensive cybersecurity, tailored to fit your needs on demand.",
    },
    {
        title: "Peerless Knowledge",
        subTitle:
            "Depend on our team of seasoned anti-fraud professionals, whose vast experience and deep insights into cybersecurity dilemmas provide bespoke solutions for your specific challenges.",
    },
    {
        title: "Exclusive Cognitive Security Technology",
        subTitle:
            "Our solutions are a testament to our vast cybersecurity knowledge, transforming human insight into AI-driven security. We innovate independently, delivering forefront proprietary cognitive security technologies.",
    },
    {
        title: "Comprehensive Channel Defense",
        subTitle:
            "Seamlessly control fraud prevention across all customer engagement platforms with our integrated console. Transition from segmented to unified channel protection effortlessly.",
    },
];


export const aboutUsServices = [

    {
        title: 'Fintech Innovations',
        icon: fintech, // Ensure these are valid references to your icons
        width: 250,
        height: 250,
        desc: 'The fintech industry is revolutionizing traditional financial services, offering new avenues for consumer and business finance. Amidst rapid growth, fintechs must protect their innovations from cyber threats while remaining focused on their mission. Our solution partners with fintech companies, providing comprehensive tools for secure, compliant service delivery. Our platform, especially via SaaS, offers quick integration and a reliable framework for fintech security and operational excellence.'
    },
    {
        title: 'Financial Services',
        icon: internet, // Ensure these are valid references to your icons
        width: 300,
        height: 270,
        desc: "As financial institutions become increasingly digitized, they attract cybercriminals targeting sensitive user data and corporate finances. With the rise of mobile banking, the challenge lies in balancing advanced security with seamless customer experiences. Financial entities need a sophisticated solution to preemptively counter fraud and secure assets. Our Cognitive Security Platform offers a solid defense, enhancing digital security without compromising on efficiency, thus accelerating return on investment and operational agility."
    },
    {
        title: 'Online Gaming Security',
        icon: bet, // Ensure these are valid references to your icons
        width: 250,
        height: 250,
        desc: 'Online gaming and betting platforms are lucrative targets for cyber fraud, with Account Takeover attacks threatening user security. Fraudsters aim to steal personal information, drain funds, or misuse loyalty rewards. Our approach integrates advanced security measures, including behavioral biometrics, to seamlessly identify and mitigate suspicious activities. Our system ensures user protection through an unobtrusive monitoring process, enhancing security while maintaining a frictionless user experience.'
    },
    {
        title: 'Automotive Technologies',
        icon: car, // Ensure these are valid references to your icons
        width: 250,
        height: 200,
        desc: 'The automotive industry is steering towards connected vehicle ecosystems, enhancing customer services through apps that offer remote controls, tracking, and diagnostics. This digital shift, however, opens doors to cyber threats, with fraudsters targeting vehicle access and data. Our platform recognizes the importance of safeguarding brand credibility and consumer trust, providing robust security measures tailored for automotive applications, ensuring peace of mind for manufacturers and users alike.'
    },

];


export const system = [
    {
        title: 'Users',
        icon: system1,
        content: 'Streamlining secure, multi-device finance services for an intuitive and safeguarded user experience.',
    },
    {
        title: 'Indicators',
        icon: system2,
        content: 'Creating comprehensive digital profiles with tech and behavior indicators for personalized security.',
    },
    {
        title: 'Tech',
        icon: system3,
        content: 'Tech indicators assess cybersecurity, ensuring devices meet strict standards against digital threats.',
    },
    {
        title: 'Behavioral Biometrics',
        icon: system4,
        content: 'Refining security through meticulous analysis of user-device interaction biometrics.',
    },
    {
        title: 'Behavioral Analytics',
        icon: system5,
        content: 'Our analytics uncover patterns and anomalies for preemptive online fraud detection.',
    },
    {
        title: 'Risk Score',
        icon: system6,
        content: 'Evaluating engagement risks dynamically to calibrate security measures appropriately.',
    },
];



export const systemAccordion = [
    {
        id: 'panel1',
        title: 'Enhance Customer Security',
        content: 'Transition from simply knowing to actively securing your customers. Utilize our behavior-based algorithms to validate identities, securing your digital platform and its users.',
    },
    {
        id: 'panel2',
        title: 'Integrated Protection Layers',
        content: 'Deploy multifaceted anti-fraud measures across different aspects of user interaction, uniting an array of security features within a singular, cohesive product. Customize according to your needs, ensuring a scalable and code-free integration.',
    },
    {
        id: 'panel3',
        title: 'Streamlined System Integration',
        content: 'Our platform seamlessly incorporates with existing third-party or bespoke solutions, complementing and enhancing the security infrastructure already in place.',
    },
    {
        id: 'panel4',
        title: 'Cross-Channel Defense',
        content: 'Our holistic approach to fraud prevention covers all banking channels, centralizing fraud management to enhance efficiency and control across the board.',
    },
    {
        id: 'panel5',
        title: 'Optimized Fraud Prevention Workflows',
        content: 'Customize your anti-fraud response with our flexible platform, designed for high-efficiency management and rapid deployment, promising a better return on investment.',
    },
    {
        id: 'panel6',
        title: 'Seamless Security Experience',
        content: 'Our biometric analysis reduces user friction and elevates security, ensuring a seamless authentication process while upholding rigorous security standards.',
    },
];

