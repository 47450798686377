import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import './styles.scss';
import systemAnimation from '../../../assets/lottie/system.json';
import Lottie from 'react-lottie';
import { system } from '../../../data';

const SystemSecondComponent = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: systemAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <>
            {/* <Box>
                <Typography variant='h2' fontWeight='bold' sx={{ padding: '8px' }}>
                    Our System
                </Typography>
            </Box> */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                {system.map((section, index) => (
                    <Box className="SystemSecondComponente-card SystemSecondComponentplaying">
                        <Box className="SystemSecondComponentwave"></Box>
                        <Box className="SystemSecondComponentwave"></Box>
                        <Box className="SystemSecondComponentwave"></Box>
                        <Box className="SystemSecondComponentinfotop">
                            <img src={section?.icon} width={65} height={70} className="SystemSecondComponenticon" />
                            <br />
                            {section?.title}
                            <br />
                            <Box className="SystemSecondComponentname">{section?.content}</Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        </>
    )
}

export default SystemSecondComponent