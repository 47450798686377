import React from 'react'
import './styles.scss'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const AboutUsFourthComponent = () => {
    return (
        <Box className="AboutUsFourthComponentWrapper">
            <Typography variant='h2' className='AboutUsFourthComponentTitle'>
                Why Wait Any Longer?
            </Typography>
            <Typography variant='h3' className='AboutUsFourthComponentSubTitle'>
                Start safeguarding your digital services now.
            </Typography>

            <Link to='/signin' className='AboutUsFourthComponentLink'>
                <Button className='AboutUsFourthComponentButton'>
                    Message Us
                </Button>
            </Link>
        </Box>
    )
}

export default AboutUsFourthComponent